import {ReactElement} from "react";
import {FormattedMessage} from "react-intl";
import {BlLocale} from "../../lib/BlLocale";
import {addUrlParam} from "../../lib/urlParsing";

export interface LanguageButtonProps {
	language: BlLocale,
	enabled: boolean
}

export function LanguageButton(props: LanguageButtonProps): ReactElement {

	return (
		<div id={props.language} className={`tw-flex tw-p-8 ${!props.enabled ? 'tw-text-grey' : ''}`}
			 onClick={() => addUrlParam(document.location.search, 'blLocaleCode', props.language)}>
			<FormattedMessage id={`lang.short.${props.language}`}/>
		</div>
	)

}