import {ReactElement, useState} from "react";
import {MenuRowEntry} from "./MenuRowEntry";
import {MenuModel} from "../desktop-menu/models/MenuModel";
import {BlogMenuModel} from "../desktop-menu/models/BlogMenuModel";
import {ArrowButton} from "./ArrowButton";
import {SubMenuRowEntry} from "./SubMenuRowEntry";
import {ActionButtonMenuModel} from "../desktop-menu/models/ActionButtonMenuModel";

export interface MenuRowProps {
    menu: MenuModel | undefined,
    blogMenu: BlogMenuModel | undefined,
    actionButtonMenu: ActionButtonMenuModel | undefined
}

export function MenuRow(props: MenuRowProps) : ReactElement {

    const [blogOpen, setBlogOpen] = useState(false);

    return(
        <div className={"tw-flex tw-flex-1 tw-flex-col"}>
            {props.menu && props.menu?.entries.map( (entry, index) => (
                <MenuRowEntry
                    key={index}
                    listId={index}
                    menuItem={entry} />
            ))}
            {props.blogMenu && (
                <div className={"tw-my-6"}>
                    <div className={"tw-flex tw-flex-row tw-px-4 tw-background-green"} onClick={() => {setBlogOpen(!blogOpen)}}>
                        <ArrowButton open={blogOpen} />
                        <span className={`tw-ml-2 tw-text-3xl tw-text-black tw-font-bold tw-font-gotham-black-italic`}>
                            {props.blogMenu.title}
                        </span>
                    </div>
                    <div className={"tw-bg-menuSelectedBackground tw-mt-4 tw-pr-8 tw-pl-16"}>
                        {blogOpen ?
                            <div className={"tw-flex tw-flex-col"}>
                                {props.blogMenu.rows.map((row, index) => (
                                    <SubMenuRowEntry
                                        key={index}
                                        menuEntryRow={row} />
                                ))}
                            </div>
                        : ''}
                    </div>
                </div>
            )}
            {props.actionButtonMenu && (
                <div className={"tw-my-6"}>
                    <div className={`tw-flex tw-flex-row tw-px-4`} onClick={() => {window.open(props.actionButtonMenu!.url, '_blank')}}>
                        <div className={`${props.actionButtonMenu.blackFriday ? 'tw-bg-red tw-p-4' : ''}`}>
                            <span className={`tw-ml-2 tw-text-3xl ${props.actionButtonMenu.blackFriday ? 'tw-font-gotham-black tw-font-bold tw-text-white' : 'tw-text-black tw-font-bold tw-font-gotham-black-italic tw-text-menuActionButton'} `}>
                                {props.actionButtonMenu.title.toUpperCase()}
                            </span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

}