import {ReactElement} from "react";
import {redirectTo} from "../../lib/utility";

export function Logo() : ReactElement {

    return(
        <div className={"tw-mb-8 tw-px-8 tw-pt-8"} onClick={() => redirectTo('/')}>
            <img alt={"JustDrink"}
                 className={"tw-w-mobileMenuIcon"}
                 src={"/img/fs/justDrink-logo_brown.svg"}
            />
        </div>
    );

}