import {ReactElement, useState} from "react";
import {redirectTo} from "../../lib/utility";
import {MenuEntryRowModel} from "../desktop-menu/models/MenuEntryRowModel";
import {ArrowButton} from "./ArrowButton";

export interface SubMenuRowEntryProps {
    menuEntryRow: MenuEntryRowModel
}

export function SubMenuRowEntry(props: SubMenuRowEntryProps) : ReactElement {

    const [open, setOpen] = useState(false);

    const trackNavigationEvent = (title: string) => {
        // @ts-ignore
        window.dataLayer.push({
            'event': 'Tracking Event',
            'category': 'Navigation Tracking',
            'action': 'Navigation Level 1 ' + props.menuEntryRow.title,
            'label': 'Navigation Level 2' + title
        });
    }

    return(
        <div className={"tw-py-6"}>
            <div className={"tw-flex tw-flex-row"} onClick={() => setOpen(!open)}>
                <ArrowButton open={open} />
                <span className={"tw-text-2xl tw-text-black tw-ml-4"}>{props.menuEntryRow.title}</span>
            </div>
            {open ?
            <div className={"tw-flex tw-flex-col"}>
                {props.menuEntryRow && props.menuEntryRow.entries.map((entry, index) => (
                    <div className={"tw-py-6"} key={index} onClick={() => {
                        trackNavigationEvent(entry.title);
                        redirectTo(entry.url)
                    }}>
                        <span>{entry.title}</span>
                    </div>
                ))}
            </div> : ''}
        </div>
    );
}
