import {ReactElement} from "react";
import {FormattedMessage} from "react-intl";
import {redirectTo} from "../../lib/utility";

export function LoginRow(props: { isAnonymous: boolean }): ReactElement {

	return (
		<div className={"tw-flex tw-pt-8 tw-text-black tw-mt-24"}>
			{props.isAnonymous ?
				<div className={"tw-flex"}>
					<div className={"tw-py-4 tw-pl-4 tw-pr-12 tw-ml-4"} onClick={() => redirectTo('/register')}>
						<FormattedMessage id={"home.register"}/>
					</div>
					<div className={"tw-py-4 tw-px-12 tw-border tw-border-grey"} onClick={() => redirectTo('/login')}>
						<FormattedMessage id={"home.login"}/>
					</div>
				</div>
				:
				<div className={"tw-flex tw-flex-1 tw-justify-center"}>
					<div className={"tw-py-4 tw-px-12 tw-font-bold"} onClick={() => redirectTo('/logout')}>
						<FormattedMessage id={"home.logout"}/>
					</div>
				</div>
			}
		</div>
	);

}