import {ReactElement} from "react";

export function CloseButton(props: {closeMenu: () => void}) : ReactElement {

    return(
        <div onClick={props.closeMenu} className={"tw-bg-black tw-opacity-75 tw-flex tw-w-mobileMenuCloseButton tw-justify-center tw-p-4"}>
            <div className={"tw-cursor-pointer"}>
                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.7677 8.03057L22.0052 6.26807L15.0177 13.2556L8.0302 6.26807L6.2677 8.03057L13.2552 15.0181L6.2677 22.0056L8.0302 23.7681L15.0177 16.7806L22.0052 23.7681L23.7677 22.0056L16.7802 15.0181L23.7677 8.03057Z" fill="white"/>
                </svg>
            </div>
        </div>
    );

}