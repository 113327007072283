import {ReactElement} from "react";
import {LanguageButton} from "./LanguageButton";
import {i18nSelectors} from "../bundle-entry/i18n/i18nSlice";
import {useSelector} from "react-redux";

export function LanguageRow() : ReactElement {

    const locale = useSelector(i18nSelectors.getCurrentLocale);

    return(
        <div className={"tw-flex tw-justify-center tw-mt-4 tw-pb-8 tw-text-black"}>
            <LanguageButton
                language={'de_CH'}
                enabled={locale === 'de_CH'} />
            <LanguageButton
                language={'fr'}
                enabled={locale === 'fr'} />
            <LanguageButton
                language={'it'}
                enabled={locale === 'it'} />
        </div>
    );

}
