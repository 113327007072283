import {ReactElement, useEffect, useState} from "react";
import {CloseButton} from "./CloseButton";
import {LanguageRow} from "./LanguageRow";
import {LoginRow} from "./LoginRow";
import {Logo} from "./Logo";
import {MenuRow} from "./MenuRow";
import {Transition} from '@headlessui/react'
import {getActionButtonMenu, getBlogMenu, getMenu} from "../../lib/api/menuApi";
import {MenuModel} from "../desktop-menu/models/MenuModel";
import {BlogMenuModel} from "../desktop-menu/models/BlogMenuModel";
import AccountIcon from "../topnavbar-menu/AccountIcon";
import {FormattedMessage} from "react-intl";
import {redirectTo} from "../../lib/utility";
import {ActionButtonMenuModel} from "../desktop-menu/models/ActionButtonMenuModel";
import {reduxStore} from "lib/redux/reduxStore";
import {
	mobileMenuReducer,
	activateSidebar,
	deactivateSidebar,
} from "components/mobile-menu/mobileMenuSlice";
import { useDispatch } from 'react-redux';

reduxStore.injectReducer("mobileMenu", mobileMenuReducer);

export interface MobileMenuProps {
	isAnonymous: boolean
}

export function MobileMenu(props: MobileMenuProps): ReactElement {

	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [menu, setMenu] = useState<MenuModel>();
	const [blogMenu, setBlogMenu] = useState<BlogMenuModel>();
	const [actionButtonMenu, setActionButtonMenu] = useState<ActionButtonMenuModel>();

	function toggleModal() {
		setOpen(!open);
		if (open) {
			document.body.style.position = '';
			dispatch(deactivateSidebar());
		} else {
			document.body.style.position = 'fixed';
			dispatch(activateSidebar());
		}
	}

	useEffect(() => {
		getMenu()
			.then(result => setMenu(result));
		getBlogMenu()
			.then(result => setBlogMenu(result));
		getActionButtonMenu()
			.then(result => setActionButtonMenu(result));
	}, []);

	return (
		<>
			<div onClick={() => toggleModal()}>
                <span className="up-to-sm hamburger menu-button">
                    <i className="fa fa-bars"></i>
                </span>
			</div>
			<Transition
				style={{height: document.documentElement.clientHeight}}
				className={"tw-absolute tw-flex tw-flex-1 tw-flex-row tw-top-0 tw-w-screen tw-left-0 tw-z-50"}
				enter="tw-transition-all tw-duration-300"
				enterFrom="tw-opacity-0"
				enterTo="tw-opacity-100"
				leave="tw-transition-opacity tw-duration-300"
				leaveFrom="tw-opacity-100"
				leaveTo="tw-opacity-0"
				show={open}>
				<div className={"tw-bg-white tw-flex-1 tw-flex-col tw-overflow-y-scroll"}>
					<Logo/>
					{!props.isAnonymous &&
						<div className={"tw-bg-grey tw-bg-opacity-10 tw-flex tw-flex-row tw-p-4 tw-align-center"}
							 onClick={() => redirectTo("/my-account")}>
							<AccountIcon/>
							<div
								className={"tw-ml-4 tw-uppercase tw-font-bold tw-flex tw-flex-1 tw-leading-mobileMenuAccountRow"}>
								<FormattedMessage id={"frontend.topnavbarmenu.my.account"}/>
							</div>
						</div>
					}
					<MenuRow
						menu={menu}
						blogMenu={blogMenu}
						actionButtonMenu={actionButtonMenu} />
					<LoginRow
						isAnonymous={props.isAnonymous}/>
					<LanguageRow/>
				</div>
				<CloseButton closeMenu={() => toggleModal()}/>
			</Transition>
		</>
	)

}
