import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {getBlLocaleFromString} from "lib/BlLocale";
import {MobileMenu} from "components/mobile-menu/MobileMenu";
import {BundleEntry} from "components/bundle-entry/BundleEntry";

const target = document.getElementById('mobile-menu');
const rawLocaleCode = target?.dataset?.['localeCode'];
const localeCode = getBlLocaleFromString(rawLocaleCode);
const isAnonymous = target?.dataset?.['isAnonymous'];

if (target) {
    ReactDOM.render(
        <BundleEntry
            bundleName="mobile-menu"
            locale={localeCode}
            prefetchTranslations={[
                'frontend.topnavbarmenu.my.account',
                'home.register',
                'home.login',
                'home.logout',
                'lang.short.de_CH',
                'lang.short.fr',
                'lang.short.it'
            ]}
        >
            <MobileMenu
                isAnonymous={isAnonymous === 'true'}/>
        </BundleEntry>,
        document.getElementById('mobile-menu')
    );
}
