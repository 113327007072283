import {ReactElement, useState} from "react";
import {ArrowButton} from "./ArrowButton";
import {SubMenuRowEntry} from "./SubMenuRowEntry";
import {redirectTo} from "../../lib/utility";
import {MenuEntryModel} from "../desktop-menu/models/MenuEntryModel";

export interface MenuRowEntryProps {
    menuItem: MenuEntryModel,
    listId: number
}

export function MenuRowEntry(props: MenuRowEntryProps) : ReactElement {

    const [open, setOpen] = useState(false);

    function onLinkClick() {
        if(props.menuItem.rows.length > 0) {
            setOpen(!open)
        } else {
            redirectTo(props.menuItem.url)
        }
    }

    return(
        <div className={"tw-my-6"}>
            <div className={"tw-flex tw-flex-row tw-px-4"} onClick={() => onLinkClick()}>
                <ArrowButton
                    open={open} />
                <span className={`tw-ml-2 tw-text-3xl tw-text-black tw-font-bold ${props.listId === 5 ? "tw-font-gotham-black-italic" : "tw-font-gotham-black"}`}>
                    {props.menuItem.title}
                </span>
            </div>
            <div className={"tw-bg-menuSelectedBackground tw-mt-4 tw-pr-8 tw-pl-16"}>
                {open && props.menuItem.rows?.map((row, index) => (
                    <SubMenuRowEntry
                        key={index}
                        menuEntryRow={row} />
                ))}
            </div>
        </div>
    );

}
