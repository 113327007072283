import {ReactElement} from "react";

export interface ArrowButtonProps {
    open: boolean
}

export function ArrowButton(props: ArrowButtonProps) : ReactElement {

    return(
        <div>
            {!props.open ? <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.115 8.295L5.705 9.705L11.705 15.705L17.705 9.705L16.295 8.295L11.705 12.875L7.115 8.295Z"
                            fill="#000000"/>
                    </svg>
                </div>
                :
                <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.11502 15.7051L5.70502 14.2951L11.705 8.29506L17.705 14.2951L16.295 15.7051L11.705 11.1251L7.11502 15.7051Z"
                            fill="#EDEDED"/>
                    </svg>
                </div>
            }
        </div>
    );

}